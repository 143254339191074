<template>
  <v-container fluid no-gutters>
    <div class="row row--dense">
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between " >
          <div class="d-flex bordered qp-tab-nav">
            <div class="nv_item">
              <v-autocomplete
                  :items="[]"
                  item-value="venue_service_id"
                  item-text="name"
                  outlined
                  multiple
                  placeholder="Services"
                  background-color="rgba(79, 174, 175, 0.1)"
                  hide-details
                  style="width: 150px;"
                  class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
                  :height="46"
                  dense
                  color="#4FAEAF"
                  item-color="#4FAEAF"
                  disabled
              >
              </v-autocomplete>
            </div>
            <MembershipTopTab :dashboardClass="false" :scheduleClass="false" :scheduleNewClass="false" :analyticsClass="false" :membersClass="true"/>
          </div>
        </div>
      </v-col>
    </div>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>
    <div class="md-card md-theme-default mt-8 shadow rounded-5">
      <div class="md-card-content md-card-table">
        <div>
          <div class="d-flex justify-space-between align-center">
            <SvgIcon class="text-2xl font-semibold" text="Membership Logs" >
            </SvgIcon>


            <div class="d-flex gap-x-5">
              <v-select
                  v-model="perPage"
                  :items="[10, 15, 25, 50]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  class="q-autocomplete shadow-0  mt-2"
                  hide-details
                  outlined
                  @change="getFilterData"
                  style="max-width: 120px"
                  placeholder="Per Page"
                  dense
              ></v-select>
              <v-btn
                  class="export-button mt-2"
                  elevation="0"
                  height="40"
                  v-if="
                         checkExportPermission(
                            $modules.memberships.management.slug
                          )
                      "
                  @click="exportData"
              >
                <SvgIcon text="Export" >
                  <template v-slot:icon>
                    <ExportIcon/>
                  </template>
                </SvgIcon>
              </v-btn>
            </div>
          </div>
          <div class="table-responsive mt-4">
            <table class="table border-collapse ">
              <thead >
              <tr class="opacity-70 tr-neon tr-rounded ">
                <th >
                  <div class="">Name</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Name"
                        v-model="searchParams.name"
                        item-value="name"
                        item-text="name"
                        :items="names"
                        :loading="isLoading"
                        :search-input.sync="name"
                        @change="getFilterData"

                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    ></v-autocomplete>
                  </div>
                </th>
                <th >
                  <div class="">Mobile</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Mobile"
                        v-model="searchParams.mobile"
                        item-value="mobile"
                        item-text="mobile"
                        :items="phones"
                        :loading="isLoading"
                        :search-input.sync="phone"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    ></v-autocomplete>
                  </div>
                </th>
                <th >
                  <div class="">Email</div>
                  <div class="search_column">
                    <v-autocomplete
                        solo
                        filled
                        label="Email"
                        v-model="searchParams.email"
                        item-value="email"
                        item-text="email"
                        :items="emails"
                        :loading="isLoading"
                        :search-input.sync="email"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    ></v-autocomplete>
                  </div>
                </th>
                <th >
                  <div class="">Membership</div>
                  <div class="search_column">
                    <v-select
                        solo
                        dense
                        label="Memberships"
                        :items="memberships"
                        item-text="name"
                        item-value="membership_id"
                        multiple
                        v-model="searchParams.memberships"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                    >
                      <template
                          v-if="
                                    searchParams.memberships != null &&
                                      searchParams.memberships.length ==
                                        memberships.length
                                  "
                          v-slot:selection="{ index }"
                      >
                        <span v-if="index == 0">All Memberships</span>
                      </template>
                      <template
                          v-else
                          v-slot:selection="{ item, index }"
                      >
                        <span v-if="index == 0">{{ item.name }}</span>
                        <span v-if="index == 1">, ...</span>
                      </template>

                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="
                                      toggle('memberships', 'memberships')
                                    "
                        >
                          <v-list-item-action>
                            <v-icon
                                :color="
                                          searchParams.memberships != null &&
                                          searchParams.memberships.length > 0
                                            ? 'indigo darken-4'
                                            : ''
                                        "
                            >{{
                                getIcon("memberships", "memberships")
                              }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </div>
                </th>
                <th >
                  Start Date
                </th>
                <th >
                  End Date
                </th>
                <th >
                  Package
                </th>
                <th >
                  Status
                </th>
                <th >
                  View
                </th>
              </tr>
              </thead>

              <tbody>
              <tr
                  v-for="member in membersList"
                  :key="member.id"
              >
                <td>
                  <div >
                    <span class="text_ellipse text-neon font-bold  pointer "  @click="showUserModel(member.customer_id)">
                    {{ member.first_name }}
                                {{ member.last_name }}
                    </span>


                  </div>
                </td>
                <td >
                  <div >
                    {{ member.mobile }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.email }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.membership_name }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.start_date | dateformat }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.end_date | dateformat }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.package_name }}
                  </div>
                </td>
                <td >
                  <div >
                    {{ member.status }}
                  </div>
                </td>
                <td >
                  <v-icon
                      @click="viewMembership(member.membership_id)"
                      class="pl-2"
                      color="#66c8c8"
                  >mdi-eye</v-icon
                  >
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="getFilterData"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>

    <customer-model v-bind="userModel" @close="userModel.userID = null" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CustomerModel from "./../Clients/Customer/CustomerModel";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import MembershipTopTab from "./MembershipTabs/MembershipTopTab";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {
    ExportIcon,SvgIcon,
    CustomerModel,MembershipTopTab,
  },
  data() {
    return {
      perPage: 10,
      btnShow: false,
      membersList: [],
      names: [],
      name: null,
      nationality: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      page: 1,
      searchParams: { memberships: [] },
      totalPages: 1,
      historyDialoge: false,
      historyData: [],
      receiptData: {},
      userModel: { userID: null, type: "details" },
    };
  },

  created() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getMemberships.status == false) {
      this.$store.dispatch("loadMemberships");
    }
    this.getMembersList();
  },

  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
    nationalities() {
      return this.$store.getters.getCountries.data;
    },
    memberships() {
      return this.$store.getters.getMemberships.data;
    },
  },
  watch: {
    page() {
      this.getMembersList();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
  },
  methods: {
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    toggle(type, field) {
      this.$nextTick(() => {
        if (
          this.searchParams[field] != null &&
          this.searchParams[field].length == this[type].length
        ) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type];
        }
        this.getMembersList();
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (
        this.searchParams[field] != null &&
        this.searchParams[field].length == this[type].length
      ) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field] != null &&
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },

    getMembersList() {
      let url = "";
      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.mobile != "undefined") {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.package_id == null) {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.package_id != "undefined") {
        url += "&package_id=" + this.searchParams.package_id;
      }
      if (
        this.searchParams.memberships == null ||
        this.searchParams.memberships.length == 0
      ) {
        delete this.searchParams.memberships;
      } else if (typeof this.searchParams.memberships != "undefined") {
        url += "&memberships=" + this.searchParams.memberships.join(",");
      }
      this.$http
        .get(
          `venues/memberships/logs?page=${this.page}&per_page=${
            this.perPage != null ? this.perPage : 10
          }${url}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.membersList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    exportData() {
      let url = "";
      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.mobile != "undefined") {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.package_id == null) {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.package_id != "undefined") {
        url += "&package_id=" + this.searchParams.package_id;
      }
      if (
        this.searchParams.memberships == null ||
        this.searchParams.memberships.length == 0
      ) {
        delete this.searchParams.memberships;
      } else if (typeof this.searchParams.memberships != "undefined") {
        url += "&memberships=" + this.searchParams.memberships.join(",");
      }
      this.$http
        .get(`venues/memberships/logs/export?order_by=m.id${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadFile(response, "Membership_Export_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .get(
          "venues/memberships/members/filters?field=" + type + "&search=" + val
        )
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.getMembersList();
    },
    getRenewHistory(userId) {
      this.$http
        .get(`venues/memberships/members/history/${userId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.historyDialoge = true;
            this.historyData = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showReceipt(data) {
      this.receiptData.customer = {
        name: data.name,
        email: data.email,
        mobile: data.mobile,
      };
      this.receiptData.timestamp = data.timestamp;
      this.receiptData.price = data.price;
      this.receiptData.packageName = data.package;
      this.receiptData.start_date = data.start_date;
      this.receiptData.end_date = data.end_date;
      this.receiptData.type = "MEMBERSHIP";
      this.receiptData.id = data.id.toString();
      this.receiptData.moduleId = parseInt(
        this.$modules.memberships.management.id
      );
      this.receiptData.cashier = data.cashier;
      this.historyDialoge = false;
      let vat = parseFloat(data.price * 5) / 100;
      let item = {
        title: `${data.membership} - Package ${data.package} `,
        quantity: 1,
        price: data.price,
        discount: 0,
        discount_amount: 0,
        vat_rate: "5%",
        vat_amount: vat,
        total_price: vat + parseFloat(data.price),
      };
      this.receiptData.discount = false;
      this.receiptData.items = [];
      this.receiptData.items.push(item);
    },
    receiptClose() {
      this.receiptData.id = null;
      this.historyDialoge = true;
    },
    customerForward(id) {
      this.$router.push(
        {
          name: "CustomersByID",
          params: { id: id },
        },
        () => {}
      );
    },
    viewMembership(membership_id) {
      this.$router.push(
        {
          name: "MembershipView",
          params: { data: btoa(membership_id) },
        },
        () => {}
      );
    },
    gotoPage(route){
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.light-blue-text{
  color: rgba(17, 42, 70, 0.8) !important;
}
</style>
